var $class="se2--link-list-apccountry-selector",$name="LinkListAPCCountrySelector",$path="app/components/LinkListAPCCountrySelector/index.js",$this={$class,$name,$path,};import shell from 'app/modules/shell';

import { checkPageExistenseOnOtherLocale, createSupposePageUrl } from 'app/partials/countrySelectorBase';
import { countryListSelector } from 'app/partials/countrySelectorBase/config';

import getCountryLanguageCodes from 'app/utilities/getCountryLanguageCodes';
import setCookie from 'app/utilities/setCookie';

import forEach from 'lodash/forEach';
import esapi from 'node-esapi';

const apcParamName = 'ref_url';
const apcParamValue = '/shop/tradeups/';
const regionItemSelector = `.${countryListSelector} > div`;
const dataTradeUpAttribute = 'data-trade-up';
const countryCodeCookieName = 'APCSITECODE';
const allowedSearchParamForReferrer = 'isocountrycode';
const allowedProtocolForReferrer = 'https:'; // http: for localhost
const expirationTime = 60 * 60 * 24 * 90; // 90 days

let urlSearchParam = null;
let isTradeUpPage = null;
let referrerPage = null;

const removeNonTradeUpsLinks = (regionWrapElement) => {
  forEach(
    regionWrapElement.querySelectorAll(`${regionItemSelector} a`),
    (el) => {
      if (!el.hasAttribute(dataTradeUpAttribute)) el.remove();
    },
  );
};

export default shell.registerComponent($this, ({ addEventListener, configs, mount, publishMessage, log }) => {
  const handleLocationChanging = (regionWrapElement) => {
    addEventListener(regionWrapElement, 'click', (event) => {
      const { target } = event;
      if (!target.href) return;
      event.preventDefault();

      if (referrerPage.includes('africa')) {
        referrerPage = referrerPage.replace('africa', 'xf');
      }

      if (target.href.includes('africa')) {
        target.href = target.href.replace('africa', 'xf');
      }

      const { countryCode, languageCode } = getCountryLanguageCodes(target.href);
      referrerPage = createSupposePageUrl(referrerPage, countryCode, languageCode);

      if (countryCode && languageCode) {
        setCookie(`${countryCodeCookieName}`, `${window.sdlCclcToIsoMap[`/${countryCode}/${languageCode}`]}`, {
          expires: expirationTime,
          SameSite: 'None',
          Secure: true,

          // Reverse Proxy team has the same domain for the APCSITECODE cookie.
          // We should do that as-is for compatibility.
          domain: '.apc.com',
        });
      }

      const referrerPageUrlForStatusCheck = new URL(referrerPage, window.location.origin);
      checkPageExistenseOnOtherLocale(referrerPageUrlForStatusCheck).then((status) => {
        if (status >= 400) {
          window.location.href = target.href;
        } else if ((/\/shop\//gm).test(referrerPage)) {
          referrerPage = referrerPage.replace('/shop/', '');
          window.location.href = `/shop/${referrerPage}`;
        } else {
          window.location.href = referrerPage;
        }
      }).catch((error) => {
        log.error(`Error in coutrySelectorList, ${referrerPage}`, error);
        window.location.href = target.href;
      });
    });
  };

  // TODO: check optimization for calling window.location. several times

  mount((element) => {
    if (window.location.search) {
      const referrerUrl = new URL(
        new URLSearchParams(window.location.search).get(apcParamName),
        window.location.origin,
      );
      referrerPage = referrerUrl.pathname;
      if (referrerPage.includes('africa')) {
        referrerPage = referrerPage.replace('africa', 'xf');
      }
      const referrerUrlSearchParam = new URLSearchParams(referrerUrl.search).get(
        allowedSearchParamForReferrer,
      );
      if (referrerUrlSearchParam) {
        referrerPage = referrerPage.concat(`?${
          allowedSearchParamForReferrer}=${esapi.encoder().encodeForURL(referrerUrlSearchParam)}`);
      }
      if (referrerUrl.protocol !== allowedProtocolForReferrer) {
        referrerPage = '';
      }
      urlSearchParam = (!referrerPage || referrerPage === '/')
        ? null
        : referrerPage;
      isTradeUpPage = !!urlSearchParam && urlSearchParam.includes(apcParamValue);
    }

    const regionWrapElement = element.querySelector(`.${countryListSelector}`);

    if (regionWrapElement) {
      if (isTradeUpPage) removeNonTradeUpsLinks(regionWrapElement);
      if (urlSearchParam) handleLocationChanging(regionWrapElement);
    }
    const closeButtonSelector = element.querySelector('.button-close');

    const closeCountrySelector = () => {
      const referrerUrl = new URL(
        new URLSearchParams(window.location.search).get(apcParamName),
        window.location.origin,
      );
      window.location.href = referrerUrl.href;
    };

    addEventListener(closeButtonSelector, 'click', closeCountrySelector);
    publishMessage(configs.messages.focusFirstRegionOfCountrySelector);
  });
});
